<template>
  <div class="px-5 sm:mx-52 my-20 ">
      <div class="grid grid-cols-1 sm:grid-cols-1 my-10">
        <p class="RobotoBold text-2xl lg:text-3xl" style="color: #821740;">
          Inscripción de Ahorro para Menores de Edad
        </p>
        <div class="liner-yellow"></div>
      </div>
      
      <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-24 mb-10">
        <div class="mx-5">
          <img src="/images/savings_for_minors/minor.png"/>
        </div>

        <div class="mt-20 col-start-2 col-span-3">
          <div>
            <p class="font-extrabold color-grey text-1xl RobotoMedium">Como socio FONCABSA tienes el beneficio de iniciar un ahorro
              para tus hijos. Solo tienes que cumplir los siguientes Requisitos
            </p>
          </div>

          <div>
            <div class="flex my-1">
              <img class="my-2" src="/images/savings_for_minors/triangulo_amarillo.png"/>
              <p class="text-md mt-2"> Ser Socio Cooperativista Activo</p>
            </div>

            <div class="flex my-1">
              <img class="my-2" src="/images/savings_for_minors/triangulo_amarillo.png"/>
              <p class="text-md mt-2"> El menor de edad debe tener entre 3 y 17 años</p>
            </div>

            <div class="flex my-1">
              <img class="my-2" src="/images/savings_for_minors/triangulo_amarillo.png"/>
              <p class="text-md mt-2"> Monto de apertura $25.00 (veniticinco pesos 00/100 m.n)</p>
            </div>

            <div class="my-10 mr-10">
              <button id="button_popups" v-on:click="redirectTo('/login?redirect=/cuenta/saving-minors')">
                Comienza tu ahorro
              </button>
            </div>

          </div>
        </div>
      </div>

    <br>

  </div>
    
</template>

<script>
export default {
  data() {
    return {
    };
  },
  name: 'SavingR',
  components: {
  },
  methods: {
    redirectTo(route){
        window.location.href = route;
    }
  }
}
</script>

<style>
#button_popups {
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  background-color: #feb72b !important;
  color: #fff !important;
  font-weight:bold !important;
  width: 250px !important;
  height: 40px !important;
  padding-top: 0% !important;
  font-size:16px !important;
}

.liner-yellow{
  width: 100px;
  background: #f8c603;
  height: 5px; 
}
</style>

<style scoped>

.text-info{
  font-family: "RobotoRegular";
}
.color-purple {
  color: #810042;
}
.color-grey {
  color: #726659;
}
</style>